import React from 'react'
import Earth from '../assets/earth.svg'
import Flowers from '../assets/flowers-btm.png'

const CarbonZero = () => {
    return (
        <div className='relative-container'>
            <img src={Flowers} alt='flowers' className='flowers-frame' />
            <div className='carbon-container'>
                <div className='img-wrapper'>
                    <img className='earth-img' src={Earth} alt='illustration of earth' />
                </div>

                <div className='text-wrapper'>
                    <h2 className='text-wrap'>Join Us in the Fight Against Carbon. Every Purchase Contributes!</h2>
                    <p>We believe in making a difference, one purchase at a time. That's why we're proud to announce that 1% of every purchase you make with us goes directly to combating carbon emissions. This initiative is part of a broader effort in partnership with Stripe's environmental program, aimed at funding cutting-edge carbon removal technologies and projects. Together, we're not just investing in high-quality products; we're investing in a healthier planet. Your support helps us contribute to a sustainable future, proving that every small action can lead to significant positive change. Shop with us, and be a part of this vital movement towards a greener, cleaner earth.</p>
                </div>
            </div>

        </div>

    )
}

export default CarbonZero