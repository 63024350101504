import React, { useState } from 'react';
import { Form } from 'react-bootstrap';


const Filters = ({ onFilterChange }) => {
    const [filters, setFilters] = useState({
        category: '',
        gender: '',
        condition: '',
        size: ''
    });

    const handleChange = (key, value) => {
        const newFilters = { ...filters, [key]: value };
        setFilters(newFilters);
        onFilterChange(newFilters);
    };

    return (
        <div>
            <Form className='form-container-filters farsan-text'>
                <div className='filter-selection'>
                    <Form.Group>
                        <Form.Label className='form-label-filters'>Category</Form.Label>
                        <Form.Control as="select" value={filters.category} onChange={e => handleChange('category', e.target.value)}>
                            <option value="">All</option>
                            <option value="pants">Pants</option>
                            <option value="jackets">Jackets</option>
                            <option value="sweaters">Sweaters</option>
                            <option value="shirts">Shirts</option>
                        </Form.Control>
                    </Form.Group>
                </div>

                <div className='filter-selection'>
                    <Form.Group>
                        <Form.Label className='form-label-filters'>Gender</Form.Label>
                        <Form.Control as="select" value={filters.gender} onChange={e => handleChange('gender', e.target.value)}>
                            <option value="">All</option>
                            <option value="Men's">Men</option>
                            <option value="Women's">Women</option>
                        </Form.Control>
                    </Form.Group>
                </div>

                <div className='filter-selection'>
                    <Form.Group>
                        <Form.Label className='form-label-filters'>Condition</Form.Label>
                        <Form.Control as="select" value={filters.condition} onChange={e => handleChange('condition', e.target.value)}>
                            <option value="">All</option>
                            <option value="new">New</option>
                            <option value="used">Used</option>
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className='filter-selection'>
                    <Form.Group>
                        <Form.Label className='form-label-filters'>Size</Form.Label>
                        <Form.Control as="select" value={filters.size} onChange={e => handleChange('size', e.target.value)}>
                            <option value="">All</option>
                            <option value="small">Small</option>
                            <option value="medium">Medium</option>
                            <option value="large">Large</option>
                        </Form.Control>
                    </Form.Group>
                </div>

            </Form>
        </div>
    );
};

export default Filters;
