import React from 'react';
import { Link } from 'react-router-dom'; // Make sure to import Link
import logo from '../assets/ve-stamp.png';

const Footer = () => {
    return (
        <>
            <div className='footer-container mt-64 flex-col farsan-text'>
                <div className='footer-wrapper'>
                
                        <ul className='footer-text-container'>
                            <li><Link to="/shipping-policy">Shipping Policy</Link></li>
                            <li><Link to="/shipping-policy">Return Policy</Link></li>
                        </ul>
       
                    <img className='ve-stamp' src={logo} alt="logo of elephant" />
             
                        <ul className='footer-text-container'>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/contact-form">Contact Us</Link></li>
                        </ul>
         
                </div>

                <span className='pb-6'>&copy; 2024 Vintage Elephant. All rights reserved.</span>
            </div>

        </>

    );
}

export default Footer;
