import React from 'react';

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className='shippingPolicy mt-64 main-container'>
      <section>
        <h2>Privacy Policy</h2>
        <p>This Privacy Policy describes how Vintage Elephant ("we", "us", or "our") collects, uses, and protects the personal information of visitors and users ("you" or "your") of our website.</p>
      </section>
      
      <section>
      <h3>Information We Collect</h3>
        <p>We collect the following types of personal information:</p>
        <ul>
          <li><strong>Subscription List:</strong> If you subscribe to our newsletter or marketing communications, we collect your name and email address.</li>
          <li><strong>Transactions:</strong> If you make a purchase, we collect your name, email address, and shipping address.</li>
        </ul>
      <br></br>
      </section>
      
      <section style={{width:'100%'}}>
        <h3>Sharing Personal Information</h3>
        <p>We do not share your personal information with third parties.</p>
      </section>
      <section>
        <h3>Protection of Personal Information</h3>
        <p>We protect your personal information through encryption and secure servers to prevent unauthorized access.</p>
      </section>
      <section>
        <h3>Retention of Personal Information</h3>
        <p>We retain your personal information until you unsubscribe from our newsletter. Payment information provided during transactions is stored securely by our payment processor, Stripe. Please refer to Stripe's Privacy Policy for more information on their data retention practices.</p>
      </section>
      <section>
      <h3>Use of Cookies</h3>
        <p>We use cookies and similar tracking technologies, such as Google Analytics, to analyze website traffic and improve user experience.</p>
      </section>
      <section>
      <h3>Compliance with Privacy Laws</h3>
        <p>We comply with relevant privacy laws and regulations, including the California Consumer Privacy Act (CCPA). We allow users to unsubscribe from our marketing communications and provide transparency about how their data is used.</p>
      </section>
      <section>
      <h3>Contact Us</h3>
        <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:contact@example.com">contact@example.com</a>.</p>
      </section>
      </div>
    );
  }
}

export default PrivacyPolicy;
