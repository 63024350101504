import { useContext } from 'react';
import { CartContext } from '../CartContext';
import { getProductData } from '../productsStore';

const CartProduct = (props) => {
    const cart = useContext(CartContext);
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);

    return (
        <>
            <div className="cart-product">
                <div className='product-container'>
                    <div>
                        <img src={productData.image} alt={productData.title} style={{ width: '100px' }} />
                    </div>
                    <div className='cart-text'>
                        <h6>{productData.title}</h6>
                        <span>Quantity: ({quantity}) - </span>
                        <span>Total: ${(quantity * productData.price).toFixed(2)}</span>
                        <button className='purple-btn no-rotate' onClick={() => cart.deleteFromCart(id)}>Remove</button>
                    </div>
                </div>
         

                
            </div>
            <hr />
        </>
    )
}

export default CartProduct;
