import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("xyyrblzd");
  if (state.succeeded) {
      return (
        <section>
            <p style={{marginBottom: '3rem'}}>&nbsp;</p>
            <h3 className='farsan-text' style={{textAlign:'center', marginTop: '4rem' }}>Thank you! <br></br> We will get back to you within 24hrs.</h3>
        </section>
        
    )
  }
  return (
    <section className='form-contact-container farsan-text mt-64'>
        <h1>Contact Form</h1>
        <h2 className='farsan-text'>How can we help you?</h2>
        <form onSubmit={handleSubmit}>
        <div className='input-form-container'>
            <label for='name'>Name</label>
            <input
            id="name"
            type="name" 
            name="name"
            className='input-form'
            required
        />
        <ValidationError 
            prefix="Name" 
            field="name"
            errors={state.errors}
        />
        </div>    
       <div className='input-form-container'>
       <label for='email'>Email</label>
        <input
            id="email"
            type="email" 
            name="email"
            className='input-form'
            required
        />
        <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
        />
       </div> 
       <div className='input-form-container'>
       <label for='message'>Message</label>
        <textarea
            id="message"
            name="message"
            className='input-form'
        />
        <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
        />
       </div> 
      
 
         <input 
        type="hidden" 
        name="_subject" 
        value="customer question" 
      />
      <button type="submit" disabled={state.submitting} className='purple-btn no-rotate farsan-text'>
        Submit
      </button>
    </form>

    </section>

  );
}

export default ContactForm;
