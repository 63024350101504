import { useForm, ValidationError } from '@formspree/react';

function SubscriberForm() {
  const [state, handleSubmit] = useForm("xyyrblzd");
  if (state.succeeded) {
      return (
        <section className='form-subscribe-container'>
            <h3 className='farsan-text'>Thanks for joining!</h3>
        </section>
        
    )
  }
  return (
    <section className='form-subscribe-container'>
        <h2 className='farsan-text'>Sign up to our newsletter to recieve special offers</h2>
              <form onSubmit={handleSubmit}>
      <input
        id="email"
        type="email" 
        name="email"
        placeholder="Enter your email address"
        className='farsan-text indent'
        required
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
 
         <input 
        type="hidden" 
        name="_subject" 
        value="new_subscriber" 
      />
      <button type="submit" disabled={state.submitting} className='purple-btn no-rotate farsan-text'>
        Submit
      </button>
    </form>

    </section>

  );
}

export default SubscriberForm;
