import { createContext, useState, useEffect } from "react";
import { productsArray, getProductData } from "./productsStore";

export const CartContext = createContext();

export function CartProvider({ children }) {
    const [cartProducts, setCartProducts] = useState(() => {
        // Retrieve cart data from local storage if available
        const localData = localStorage.getItem('cart');
        return localData ? JSON.parse(localData) : [];
    });

    // Store cart data in local storage whenever the cart changes
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cartProducts));
    }, [cartProducts]);

    function getProductQuantity(id) {
        const product = cartProducts.find(p => p.id === id);
        return product ? product.quantity : 0;
    }

    function addOneToCart(id) {
        const productData = getProductData(id);
        const currentProduct = cartProducts.find(p => p.id === id);
        const currentQuantity = currentProduct ? currentProduct.quantity : 0;

        if (currentQuantity < productData.quantity) {
            const newCartProducts = currentProduct
                ? cartProducts.map(product => product.id === id ? {...product, quantity: product.quantity + 1} : product)
                : [...cartProducts, { id, quantity: 1 }];
            setCartProducts(newCartProducts);
        } else {
            alert("Cannot add more. Stock limit reached.");
        }
    }

    function removeOneFromCart(id) {
        const currentProduct = cartProducts.find(p => p.id === id);
        if (currentProduct && currentProduct.quantity > 1) {
            setCartProducts(cartProducts.map(product => product.id === id ? {...product, quantity: product.quantity - 1} : product));
        } else {
            deleteFromCart(id);
        }
    }

    function deleteFromCart(id) {
        setCartProducts(cartProducts.filter(product => product.id !== id));
    }

    function getTotalCost() {
        return cartProducts.reduce((total, product) => {
            const productData = getProductData(product.id);
            return total + (productData.price * product.quantity);
        }, 0);
    }

    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost
    };

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
}

export default CartProvider;
