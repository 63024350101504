import React from 'react'
import LargeBanner from '../assets/truck-shipping.png'
import SmallBanner from '../assets/ship-mbile.png'

const ShippingBanner = () => {
  return (
    <div className='banner-container mt-64'>
        <img className='banner-img' src={LargeBanner} alt="shipping banner"/>
        <img className='banner-mobile-img' src={SmallBanner} alt='shipping banner' />
    </div>
  )
}

export default ShippingBanner