import React from 'react';
import used from '../assets/used.png';
import newclothing from '../assets/new.png';
import { Link } from 'react-router-dom';

const Category = () => {
    return (
        <div className='category-container mt-64'>
            <Link to="/product-catalog/condition/new">
                <img className='outline' src={newclothing} alt='New clothing category' />
            </Link>
            <Link to="/product-catalog/condition/used">
                <img className='outline' src={used} alt='Used clothing category' />
            </Link>
        </div>
    );
}

export default Category;
