import React from 'react';
import ElephantImage from '../assets/bg-hero.webp'


export default function HeroImage() {
    return (
        <header>
            <div
                className="p-5 text-center bg-image mt-64"
                style={{
                    backgroundImage: `url(${ElephantImage})`,
                    backgroundPosition: 'center', // Centers the background image
                    backgroundRepeat: 'no-repeat', // Prevents the background image from repeating
                    backgroundSize: 'cover' // Ensures the background image covers the div, maintains aspect ratio
                }}
            >
                {/* Overlay with semi-transparent background */}
                <div className='mask' style={{
                    //backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adjust color and opacity as needed
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-white'>

                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}