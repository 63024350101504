import { Row, Col, Container } from 'react-bootstrap';
import { productsArray } from '../productsStore';
import ProductCard from '../components/ProductCard';

const FeaturedUsedProducts = () => {
    // First, filter the array to include only used products
    const usedProducts = productsArray.filter(product => product.condition === 'used' && product.featured === true);

    // Then, if you want to limit the number of featured used products, you can slice the array
    const featuredUsedProducts = usedProducts.slice(0, 8);

    return (
        <Container className='align-center text-center mt-64'>
            <h2 className='mb-5'>Featured Used Products</h2>
            <Row xs={2} md={3} lg={4} className='g-4'>
                {featuredUsedProducts.slice(0, 4).map((product, idx) => (
                    <Col align="center" key={idx}>
                        <ProductCard product={product} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default FeaturedUsedProducts;