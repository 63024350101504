import React from 'react';

const ShippingPolicy = () => {
  return (
    <div className="shippingPolicy mt-64 main-container">
      <h1>Vintage Elephant Shipping Policy</h1>
      
      <section className='mt-64'>
        <h2>Our Commitment</h2>
        <p>At Vintage Elephant, we're dedicated to delivering your favorite new and used clothing selections right to your doorstep with care, speed, and sustainability in mind. We believe in transparent, hassle-free shipping so you can enjoy your purchases without worry.</p>
      </section>

      <section>
        <h2>Domestic Shipping</h2>
        <p>Currently, we only ship to domestic locations within the United States. We do not ship to exchange centers.</p>
      </section>

      <section>
        <h2>Shipping Carrier and Options</h2>
        <p>We exclusively use USPS Priority Mail for all orders to ensure your items arrive swiftly and securely. Best of all, shipping is completely free on all orders, no strings attached.</p>
      </section>

      <section>
        <h2>Processing Time</h2>
        <p>We understand you’re excited to receive your order, which is why we strive to process all orders within 1 business day. Quick and efficient handling means less waiting for you.</p>
      </section>

      <section>
        <h2>Shipping Costs</h2>
        <p>We believe in straightforward and fair pricing, which is why we offer free shipping on all orders. There are no minimum purchase requirements or hidden fees.</p>
      </section>

      <section>
        <h2>Tracking and Delivery</h2>
        <p>Once your order is on its way, we’ll send you a tracking number so you can follow its journey. Expect your package to arrive within 2-4 business days after it's shipped.</p>
      </section>

      <section>
        <h2>Packaging</h2>
        <p>We're as committed to the planet as we are to fashion. That's why we use biodegradable bags or boxes for all our shipments. It's part of our pledge to keep our footprint light and our planet green.</p>
      </section>

      <section>
        <h2>Lost or Damaged Items</h2>
        <p>We stand by our shipping process, but we know sometimes things go awry. If your order isn’t scanned as delivered, we’ll provide a full refund. For items damaged in transit, we offer hassle-free returns at no additional cost to you.</p>
      </section>

      <section>
        <h2>Returns and Exchanges</h2>
        <p>Your satisfaction is our top priority. If you’re not completely happy with your purchase, returns are on us. Please refer to our return policy for detailed instructions and information.</p>
      </section>

      <section>
        <h2>Sustainability Efforts</h2>
        <p>We're not just about fashion; we're about making a difference. In addition to using biodegradable packaging, we pledge to donate 1% of every purchase to initiatives fighting carbon emissions. It's our way of giving back to the planet that inspires us so much.</p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>Have questions about your order or our shipping practices? We’re here to help. Contact our customer service team for assistance and we’ll get back to you as soon as possible.</p>
      </section>
    </div>
  );
};

export default ShippingPolicy;
