import { Row, Col, Container } from 'react-bootstrap';
import { productsArray } from '../productsStore';
import ProductCard from '../components/ProductCard';

const Store = () => {
    // Filter the array to include only new products
    const newProducts = productsArray.filter(product => product.condition === 'new' && product.featured === true);

    // Optionally, limit the number of featured new products to display
    const featuredNewProducts = newProducts.slice(0, 8);


    return (

        <Container className='align-center text-center'>
            <h2 className='mb-5'>Featured New Products</h2>
            <Row xs={2} md={3} lg={4} className='g-4'>
                {featuredNewProducts.slice(0, 4).map((product, idx) => (
                    <Col align="center" key={idx}>
                        <ProductCard product={product} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default Store;
