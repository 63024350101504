import { Row, Col, Container } from 'react-bootstrap';
import { productsArray } from '../productsStore';
import ProductCard from '../components/ProductCard';
import { useParams } from 'react-router-dom';
import Filters from '../components/Filters';
import React, { useState, useEffect } from 'react';





const ProductCatalog = () => {
    const { condition } = useParams();
    const [filters, setFilters] = useState({
        category: '',
        gender: '',
        condition: condition || '', // Use the condition from the URL or default to ''
        size: ''
    });

    useEffect(() => {
        // Update filter state when URL parameter changes
        setFilters(prevFilters => ({ ...prevFilters, condition }));
    }, [condition]);

    let filteredProducts = productsArray.filter(product => {
        return (!filters.category || product.category === filters.category) &&
               (!filters.gender || product.gender === filters.gender) &&
               (!filters.condition || product.condition === filters.condition) &&
               (!filters.size || product.size.includes(filters.size));
    });

    return (
        <Container className='main-container mt-64'>
            <Filters onFilterChange={newFilters => setFilters({ ...filters, ...newFilters })} />
            <h2 className='mb-5 mt-64'>Product Catalog</h2>
            <Row xs={2} md={3} lg={4} className='g-4'>
                {filteredProducts.map((product, idx) => (
                    <Col align="center" key={idx}>
                        <ProductCard product={product} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default ProductCatalog;