//Base Imports
import { useState } from 'react';
import './App.css';
import NavBar from './components/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import CartProvider from './CartContext';
import Hero from './components/Hero'
import TextParagraphWithButton from './components/TextParagraphWithButton';
import Footer from './components/Footer';
import Category from './components/Category';
import FeaturedUsedProducts from './components/FeaturedUsedProducts'
import CarbonZero from './components/CarbonZero';
import SubscriberForm from './components/SubscriberForm'
import ScrollToTop from './components/ScrollToTop';
import { Helmet } from 'react-helmet';
import ShippingBanner from './components/ShippingBanner';

//PAGES
import Cancel from './pages/Cancel'
import Success from './pages/Success'
import Store from './pages/Store'
import SingleProductPage from './pages/SingleProductPage';
import ProductCatalog from './pages/ProductCatalog';
import ShippingPolicy from './pages/ShippingPolicy'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ContactForm from './components/ContactForm'




function App() {
  const organizationSchema = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Your Company Name",
    "url": "http://www.your-company-site.com",
    "logo": "http://www.example.com/logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-401-555-1212",
      "contactType": "Customer service"
    },
    "sameAs": [
      "http://www.facebook.com/your-profile",
      "http://www.twitter.com/yourProfile",
      "http://plus.google.com/your_profile"
    ]
  };
  const [showCart, setShowCart] = useState(false);
  const handleShowCart = () => setShowCart(true);
  const handleCloseCart = () => setShowCart(false);
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>
      </Helmet>
      <CartProvider>
        <BrowserRouter>
          <ScrollToTop />
          <div className='flex'>
            <NavBar showCart={showCart} handleShowCart={handleShowCart} handleCloseCart={handleCloseCart} />
          </div>

          <Routes>
            {/* Define a route for the homepage/store */}
            <Route index element={
              <>
                <Hero />
                <Category />
                <ShippingBanner />
                <TextParagraphWithButton />
                <Container>
                  <Store />
               
                  <FeaturedUsedProducts />
                  <CarbonZero />
                </Container>

              </>
            } />

            {/* Define a route for the single product page */}

            <Route path="product/:productId" element={<SingleProductPage handleShowCart={handleShowCart} />} />
            <Route path="product-catalog" element={<ProductCatalog />} />

            {/* Additional routes for filtered views */}
            <Route path="product-catalog/condition/:condition" element={<ProductCatalog />} />
            <Route path="product-catalog/category/:category" element={<ProductCatalog />} />

            {/* Other routes */}
            <Route path="success" element={<Success />} />
            <Route path="cancel" element={<Cancel />} />
            <Route path="shipping-policy" element={<ShippingPolicy />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="contact-form" element={<ContactForm />} />
          </Routes>
          <SubscriberForm />
          <Footer />
        </BrowserRouter>

      </CartProvider>
    </>
  );
}

export default App;
