import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProductCard = (props) => {
    const product = props.product;
    const MAX_TITLE_LENGTH = 40;
    const displayTitle = product.title.length > MAX_TITLE_LENGTH ?
        `${product.title.substring(0, MAX_TITLE_LENGTH)}...` : product.title;

    return (
        <Card>
            <Card.Body style={{ textAlign: 'left' }}>
                <Link to={`/product/${product.id}`}>
                    <Card.Img className='h-img-1' style={{ border: 'solid rgb(193 193 193 / 72%) 1px' }} variant="top" src={product.image} alt="product"/>
                </Link>

                <Card.Title className='farsan-text card-title'>{displayTitle}</Card.Title>
                <Card.Text style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    {product.quantity > 0 ? (
                        <div>${product.price}</div>
                    ) : (
                        <div style={{ color: 'red', fontWeight: 'bold' }}>Sold out</div>
                    )}
                    <div className='size-text'>{product.size}</div>
                </Card.Text>
                <Link to={`/product/${product.id}`} aria-label='purchase on stripe payment gateway' style={{ backgroundColor: '#ffe381', color: 'black', textDecoration: 'none' }} className='purple-btn no-rotate farsan-text'>
                    View Product
                </Link>
            </Card.Body>
        </Card>
    );
}

export default ProductCard;
