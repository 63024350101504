import React from 'react'
import '../css/main.css'
import { Link } from 'react-router-dom'

const TextParagraphWithButton = () => {
    return (
        <main className='container-p farsan-text'>
            <h1 style={{color: '#8769FA'}}>Discover Unique Vintage & Contemporary Fashion<br></br> - The Vintage Elephant Boutique</h1>
            <h2>Our quaint boutique, reflective of Portland's unique spirit and commitment to environmental stewardship, offers an exquisite selection of both new and gently used clothing. Each item in our carefully curated collection is chosen for its timeless style, quality craftsmanship, and unique
                <span className='purple'> story</span>
            </h2>
            <Link to={`/product-catalog`} className='purple-btn'>
                Shop Our Collection
            </Link>

        </main>
    )
}

export default TextParagraphWithButton