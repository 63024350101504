import { Container, Navbar, Modal } from 'react-bootstrap'
import ShoppingBag from '../assets/shoppingbag.svg'
import { useState, useContext } from 'react'
import { CartContext } from '../CartContext'
import CartProduct from './CartProduct'
import '../css/navbar.css'
import VeTextLogo from '../assets/ve-text-logo.svg';


const NavBar = ({ showCart, handleShowCart, handleCloseCart }) => {
    const cart = useContext(CartContext);


    const checkout = async () => {
        await fetch('/.netlify/functions/checkout', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ items: cart.items })
        }).then((response) => {
            return response.json();
        }).then((response) => {
            if (response.url) {
                window.location.assign(response.url);
            }
        })
    }
    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

    return (
        <>
            <Navbar className='nav-container'>


                <Navbar.Brand href="/">
                    <img src={VeTextLogo} alt="Logo" style={{ height: '30px' }} /> {/* Adjust size as needed */}
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <button className='shopping-bag' onClick={handleShowCart}>
                        <img src={ShoppingBag} alt="Shopping Bag" />
                        <span className='product-count'>{productsCount}</span>
                    </button>
                </Navbar.Collapse>



            </Navbar>

            <Modal show={showCart} onHide={handleCloseCart} dialogClassName={`right-modal ${showCart ? 'show' : ''}`} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {productsCount > 0 ?
                        <>
                    
                            {cart.items.map((currentProduct, idx) => (
                                <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
                            ))}
                            <div className='checkout-container'>
                                <span>Subtotal: ${cart.getTotalCost().toFixed(2)}</span>
                                <button className="checkout-btn" onClick={checkout}>
                                    Go to Checkout
                                </button>
                            </div>

                        </>
                        :
                        <div className='flex-container'>
                            <span className='checkout-btn no-items'>There are no items in your cart!</span>
                        </div>

                    }

                </Modal.Body>
            </Modal>
        </>

    )
}

export default NavBar