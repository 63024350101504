//levi price_1P2hB5HZxSgRxuEepfMv988V
//adidas price_1P2h8rHZxSgRxuEeYVdhvCkB
//hippyhoody price_1P2R5hHZxSgRxuEeUHPjFyll

const productsArray = [

    {
        id: "price_1P2h8rHZxSgRxuEeYVdhvCkB",
        brand: "Zara",
        gender: "Men's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2024/04/09/6615eaa6981d48119dfcc48f/m_wp_6615eaa6981d48119dfcc490.webp",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2024/04/09/6615eaa6981d48119dfcc48f/m_wp_6615eaa6981d48119dfcc4da.webp",
        image3: "https://di2ponv0v5otw.cloudfront.net/posts/2024/04/09/6615eaa6981d48119dfcc48f/m_wp_6615eaa7981d48119dfcc517.webp",
        image4: "https://di2ponv0v5otw.cloudfront.net/posts/2024/04/09/6615eaa6981d48119dfcc48f/m_wp_6615eaa7981d48119dfcc54a.webp",
        title: "Men's Zara Wide Leg Straight Fit Flat Front Dress Pants Size - 32 x 31",
        size: "32x31",
        desc: "Cream colored wide leg dress pants by Zara",
        p2p: "",
        length: "",
        features: [],
        care: [
            "Fabric: 62% polyester, 34% viscose, 4% elastic",
            "(25% of the polyester comes from recycled materials)"
        ],
        category: "pants",
        condition: "new",
        featured: true,
        quantity: 0,
        price: 24.99,
    },
    {
        id: "price_1P2hB5HZxSgRxuEepfMv988V",
        brand: "Levi's",
        gender: "Men's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2023/09/10/64fe4ecbac1b04169866acf6/m_wp_64fe50312fbf1a4d398ae39f.webp",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2023/09/10/64fe4ecbac1b04169866acf6/m_wp_64fe50317dfcc20c0a0dda4f.webp",
        image3: "",
        title: "Levi's Men's Blue Jean Denim Sherpa Lined Rancher Trucker Jacket Coat - Small",
        size: "small",
        desc: "Trucker Jacket reimagined with a loose silhouette, oversized vintage flap pockets and thick welt hand pockets.",
        p2p: "",
        length: "",
        features: [],
        care: [
            "Composition & Care",
            "100% cotton",
            "Denim",
            "Non-stretch",
            "Pointed collar",
            "Hidden Front button placket",
            "Elastic side hem",
            "Chest pockets and welt hand pockets",
            "Wash your Trucker Jacket sparingly and spot treat if needed; this increases its lifespan and saves natural resources",
            "When you eventually launder your Trucker Jacket, wash and dry it inside out with like colors; liquid detergent is recommended",
            "Imported"
        ],
        category: "jackets",
        condition: "new",
        featured: true,
        quantity: 1,
        price: 79.99,
    },
    {
        id: "price_1P5HrtHZxSgRxuEeMZ6N8nvj",
        brand: "Express",
        gender: "Men's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2022/04/29/626c8cc11e75a89604a6b2bb/m_wp_626c8e46bb593765bc7db9e4.webp",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2022/04/29/626c8cc11e75a89604a6b2bb/m_wp_626c8e46da104188a3d4cf03.webp",
        image3: "https://di2ponv0v5otw.cloudfront.net/posts/2022/04/29/626c8cc11e75a89604a6b2bb/m_wp_626c8e4767bd919309f414e6.webp",
        image4: "https://di2ponv0v5otw.cloudfront.net/posts/2022/04/29/626c8cc11e75a89604a6b2bb/m_wp_626c8e46ff83049044db661d.webp",
        title: "Men's Express Stripe Gray Hoodie Pullover Sweater Kangaroo Pocket - Medium",
        size: "small",
        desc: "Super soft Men's Express pullover hoodie sweater with vertical white stripes, drawstring, and front pocket. ",
        p2p: "22 inches",
        length: "26 inches",
        features: [],
        care: [],
        category: "sweaters",
        condition: "new",
        featured: true,
        quantity: 1,
        price: 24.99,
    },

    {
        id: "price_1P5I5fHZxSgRxuEeOUER0rqy",
        brand: "Paul Jones",
        gender: "Men's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/17/64de3c6604b36d8ea633d703/m_wp_64de3e3091e053c743cf5cdc.webp",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/17/64de3c6604b36d8ea633d703/m_wp_64de3e304bd7600bc0855c39.webp",
        image3: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/17/64de3c6604b36d8ea633d703/m_wp_64de3e3002760bd94973f0c9.webp",
        image4: "https://m.media-amazon.com/images/I/71jhCXG1rKL._AC_SX569_.jpg",
        title: "Men's Retro MOD Long Sleeve Full Placket Button Down Textured Knit Cardigan - Medium",
        size: "medium",
        desc: "Styles Similar to: Todd Snyder, Banana Republic, Express, Ban Lon, Kings Road, Vintage, Cuban Style",
        p2p: "",
        length: "",
        features: [
            "Lapel collar",
            "Button placket",
            "Ribbed sleeve cuffs & hemline",
            "Ultra soft & comfortable knitted fabric"
        ],
        care: [
            "Item Fabric:",
            "63% Viscose",
            "37% Polyamide",
        ],
        category: "sweaters",
        condition: "new",
        featured: true,
        quantity: 1,
        price: 59.99,
    },
    {
        id: "price_1P5IPOHZxSgRxuEeYshDLIsT",
        brand: "Paul Jones",
        gender: "Men's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/16/64dd81ae34e9847ae5c1852a/m_wp_64dd8439fb73042309e11da8.webp",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/16/64dd81ae34e9847ae5c1852a/m_wp_64dd84394bf9ff581b55b2ed.webp",
        image3: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/16/64dd81ae34e9847ae5c1852a/m_wp_64dd843991e053c28acc2a45.webp",
        image4: "https://m.media-amazon.com/images/I/514TPL2GqwL._AC_SX679_.jpg",
        title: "Men's Textured Knit MOD Short Sleeve Cardigan Sweater Shirt Button Down Green - Medium",
        size: "medium",
        desc: "SHORT SLEEVE FULL PLACKET RIVIERA POLO. Styles Similar to: Todd Snyder, Banana Republic, Express, Ban Lon, Kings Road, Vintage.",
        features: [
            "Lapel collar",
            "Button placket",
            "Ribbed sleeve cuffs & hemline",
            "Ultra soft & comfortable knitted fabric"
        ],
        p2p: "20 inches",
        length: "26 inches",
        care: [
            "Fabric type: 65% Viscose, 35% Polyamide",
            "Machine wash or hand wash in cold water. Do not bleach and tumble dry, hang to dry with wide-shoulder hanger to prevent the shoulders from protruding"
        ],
        category: "shirts",
        condition: "new",
        featured: true,
        quantity: 1,
        price: 59.99,
    },

    {
        id: "price_1P5KTQHZxSgRxuEe4hB8SXZD",
        brand: "Adidas",
        gender: "Men's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/13/64d98566450b42fec56d78c1/m_wp_64d98782a0e6c6cac5e16a9e.webp",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/13/64d98566450b42fec56d78c1/m_wp_64d987825d686b5f268f7b03.webp",
        image3: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/13/64d98566450b42fec56d78c1/m_wp_64d98782acf462816f71fd24.webp",
        image4: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/13/64d98566450b42fec56d78c1/m_wp_64d9878281078a933c13e9fa.webp",
        title: "Adidas Men's Sportswear Coach Jacket size - Medium",
        size: "medium",
        desc: "Men's White Adidas Sportswear Coach Jacket",
        p2p: "",
        length: "",
        features: [
            "Button-up jacket with sports heritage",
            "Made in part with recycled content",
            "Long sleeve",
            "zip pockets",
            "Collared neck",
            "Country of Origin : Imported",
            "Style : F2254MSPW007",
            "Brand : adidas"
        ],
        care: [],
        category: "jackets",
        condition: "new",
        featured: true,
        quantity: 1,
        price: 79.99,
    },
    {
        id: "price_1P5Z9RHZxSgRxuEejQqAvgyG",
        brand: "Nordstrom",
        gender: "Men's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/13/64d95a5602760b1ddbf9b378/m_661c33a70e517249a8fa958b.jpeg",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/13/64d95a5602760b1ddbf9b378/m_661c33a7c383724c5d6a6b40.jpeg",
        image3: "https://di2ponv0v5otw.cloudfront.net/posts/2023/08/13/64d95a5602760b1ddbf9b378/m_661c33a7f884711467a79124.jpeg",
        title: "Men's Nordstrom Quilted Forest Green Zip Vest Jacket - Small",
        size: "small",
        desc: "Quilted green ivy puffer vest with both zip and button closure.",
        p2p: "19.5",
        length: "25.5",
        features: [],
        care: ["Material: 100% polyester"],
        category: "jackets",
        condition: "new",
        featured: true,
        quantity: 1,
        price: 29.99,
    },
    {
        id: "price_1P5eghHZxSgRxuEeOWskR0rc",
        brand: "Ashton Sportswear",
        gender: "Men's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2023/07/29/64c5d5821b40a75ba80ad973/m_661c876b15460084e1d35c01.jpeg",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2023/07/29/64c5d5821b40a75ba80ad973/m_661c876c0a60d55021e524f1.jpeg",
        image3: "https://di2ponv0v5otw.cloudfront.net/posts/2023/07/29/64c5d5821b40a75ba80ad973/m_661c876c2d829add79b272a8.jpeg",
        image4: "https://di2ponv0v5otw.cloudfront.net/posts/2023/07/29/64c5d5821b40a75ba80ad973/m_661c876b775caf3b7ed1f818.jpeg",
        title: "Men's Vintage 60s Blue Knit Piped Collar MOD Polo Shirt Ban Lon Style - Medium",
        size: "medium",
        desc: "#Style Tags: Mid Century Modern, Rockabilly, 60s, 70s, Pointed Collar, Piped Collar, Knit",
        p2p: "19",
        length: "25.5",
        features: [],
        care: ["Material: 100% Bright Orlon Acrylic"],
        category: "shirts",
        condition: "used",
        featured: true,
        quantity: 0,
        price: 59.99,
    },
    {
        id: "price_1P6eISHZxSgRxuEecFqLaeqK",
        brand: "Zara",
        gender: "Men's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2023/03/11/640d6b3e6665f39b9f20dc3c/m_wp_640d6d1a6149732e0c5f2adb.webp",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2023/03/11/640d6b3e6665f39b9f20dc3c/m_wp_640d6d1a7f29ddd5f2325e5e.webp",
        image3: "https://di2ponv0v5otw.cloudfront.net/posts/2023/03/11/640d6b3e6665f39b9f20dc3c/m_wp_640d6d1b92e491c2cf69cd96.webp",
        image4: "https://di2ponv0v5otw.cloudfront.net/posts/2023/03/11/640d6b3e6665f39b9f20dc3c/m_wp_640d6d1b4bc655b143c7094d.webp",
        title: "Men's Zara Pleated Dress Pants Trousers Khaki Chino Size Tapered Fit 32 x 32",
        size: "32x32",
        desc: "Tan colored business casual dress pants by Zara",
        p2p: "",
        length: "",
        features: [],
        care: [],
        category: "pants",
        condition: "new",
        featured: true,
        quantity: 0,
        price: 24.99,
    },
    {
        id: "price_1P8YWdHZxSgRxuEeoXlTrSEl",
        brand: "Jantzen",
        gender: "Women's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2024/04/22/6626ef32943ddbea826e7426/m_wp_6626f2c9a2c69e9a7a59bcae.webp",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2024/04/22/6626ef32943ddbea826e7426/m_wp_6626f2c9c9af8c5a4c711126.webp",
        image3: "https://di2ponv0v5otw.cloudfront.net/posts/2024/04/22/6626ef32943ddbea826e7426/m_wp_6626f2c915460062708f4f0b.webp",
        image4: "",
        title: "Vintage Women's Sleeveless Knit Sweater Vest Cyan Blue MOD 60s USA Made Medium",
        size: "medium",
        desc: "This knit blue vest is beautiful and rich in color. Wonderful for a breezy spring day.",
        p2p: "17in",
        length: "18in",
        features: ["- Button Down",
            "- Front pockets",
            "- Ribbed Hem"
        ],
        care: [],
        category: "sweaters",
        condition: "used",
        featured: true,
        quantity: 1,
        price: 34.99,
    },
    {
        id: "price_1P8YneHZxSgRxuEePRgvpGi7",
        brand: "Michael Gerald",
        gender: "Women's",
        image: "https://di2ponv0v5otw.cloudfront.net/posts/2023/09/24/6510ca763fd8e74cf692c212/m_6626eed415ad54b5d2c68a4d.jpeg",
        image2: "https://di2ponv0v5otw.cloudfront.net/posts/2023/09/24/6510ca763fd8e74cf692c212/m_6626eed42068473fa69ab64d.jpeg",
        image3: "https://di2ponv0v5otw.cloudfront.net/posts/2023/09/24/6510ca763fd8e74cf692c212/m_6626eed4dc8a4e84186bde8d.jpeg",
        image4: "https://di2ponv0v5otw.cloudfront.net/posts/2023/09/24/6510ca763fd8e74cf692c212/m_6626eed4d253f19fad449b0c.jpeg",
        title: "Michael Gerald | VTG 90s | Patterned Knit Grandpa Knit Sweater Womens Medium",
        size: "medium",
        desc: "Geometric gray knit sweater",
        p2p: "20in",
        length: "21in",
        features: [],
        care: ["Material: 83% Acrylic, 17% Poly blend"],
        category: "sweaters",
        condition: "used",
        featured: true,
        quantity: 1,
        price: 19.99,
    },


]

function getProductData(id) {
    let productData = productsArray.find(product => product.id === id);

    if (productData === undefined) {
        console.log("product data does not exist for ID:" + id);
        return undefined;
    }

    return productData
}

module.exports = { productsArray, getProductData };
