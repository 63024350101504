import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CartContext } from '../CartContext';
import { getProductData } from '../productsStore';
import '../css/singleproduct.css';
import { DiscussionEmbed } from 'disqus-react';
import { Helmet } from 'react-helmet';

const SingleProductPage = ({ handleShowCart }) => {
    const [mainImage, setMainImage] = useState('');
    const cart = useContext(CartContext);
    const { productId } = useParams();
    const product = getProductData(productId);

    useEffect(() => {
        if (product) {
            setMainImage(product.image);
        }
    }, [product]);

    if (!product) {
        return <div>Product not found</div>;
    }

    const handleThumbnailClick = (image) => {
        setMainImage(image);
    };

    const handleAddToCart = () => {
        const currentQuantityInCart = cart.getProductQuantity(product.id);
        if (currentQuantityInCart < product.quantity) {
            cart.addOneToCart(product.id);
        } else {
            alert("You have reached the maximum quantity available for this product.");
        }
    };

    const disqusConfig = {
        url: window.location.href,  // The URL of the current product page
        identifier: productId,      // A unique ID for each product page
        title: product.title        // The title of the product for easy identification
    };

    const validImages = [product.image, product.image2, product.image3, product.image4,].filter(img => !!img);

    function mapGenderForSchema(gender) {
        const genderMap = {
            "Men's": "Male",
            "Women's": "Female",
            "Unisex": "Unisex"
        };
        return genderMap[gender] || "Unisex"; // Default to "Unisex" if undefined or not found
    }

    const structuredData = {
        "@context": "http://schema.org/",
        "@type": "Product",
        "name": product.title,
        "image": validImages,
        "description": product.desc,
        "sku": product.id,
        "brand": {
            "@type": "Brand",
            "name": product.brand
        },
        "offers": {
            "@type": "Offer",
            "priceCurrency": "USD",
            "price": product.price,
            "itemCondition": "http://schema.org/NewCondition",
            "availability": product.quantity > 0 ? "http://schema.org/InStock" : "http://schema.org/OutOfStock",
            "seller": {
                "@type": "Organization",
                "name": "Vintage Elephant"
            }
        },
        "audience": {
            "@type": "PeopleAudience",
            "audienceType": mapGenderForSchema(product.gender)
        }
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <div className="single-product-page">
                <div className='image-container'>
                    <div className="main-image">
                        <img src={mainImage} alt={product.title} />
                    </div>
                    <div className="thumbnails">
                        {validImages.map((img, index) => (
                            <img
                                key={index}
                                src={img}
                                alt={`Thumbnail ${index}`}
                                onClick={() => handleThumbnailClick(img)}
                                className="thumbnail"
                            />
                        ))}
                    </div>
                </div>
                <div className="product-info">
                    <h1>{product.title}</h1>
                    <p className='brand-title'>{product.brand}</p>
                    <p style={{ fontWeight: 'bolder', textTransform: 'uppercase' }}><span>{product.gender} </span>{product.size}</p>
                    <p>Price: ${product.price}</p>
                    <p>Condition: {product.condition}</p>
                    <p>In Stock: {product.quantity > 0 ? 'Yes' : <span style={{ color: 'red' }}>No - Out of stock</span>}</p>
                    <p>{product.desc}</p>
                    <div style={{ marginBottom: '1rem' }}>
                        {product.features && product.features.length > 0 ? <h3>Features</h3> : null}

                        <ul>
                            {product.features.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        {product.care && product.care.length > 0 ? <h3>Care Instructions</h3> : null}

                        <ul>
                            {product.care.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h4>Product Measurements</h4>
                        <li>Across Chest: {product.p2p}</li>
                        <li>Length: {product.length}</li>
                    </div>

                    <div className='btn-container'>
                        <button className='purple-btn no-rotate' onClick={handleAddToCart} disabled={product.quantity === 0}>
                            {product.quantity === 0 ? 'Out of Stock' : 'Add to Cart'}
                        </button>
                        <button className='purple-btn no-rotate white-btn' onClick={handleShowCart}>View Cart</button>
                    </div>
                </div>
            </div>
            <div className='disqus-container mt-64'>
                <DiscussionEmbed shortname="vintage-elephant" config={disqusConfig} />
            </div>
        </>
    );
};

export default SingleProductPage;
